var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ attrs, on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { staticClass: "primary", attrs: { small: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v("Embed codes")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c("copy-to-clipboard", {
                attrs: {
                  textToCopy: _vm.embedCode(_vm.video, "fixed"),
                  isTextButton: true,
                  buttonName: "Fixed size",
                },
              }),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c("copy-to-clipboard", {
                attrs: {
                  textToCopy: _vm.embedCode(_vm.video, "responsive"),
                  isTextButton: true,
                  buttonName: "Responsive",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }